import * as React from 'react';
import clsx from 'clsx';
import Booking from 'components/feedback';
import Bullets from 'components/bullets';
import GetConsultation from 'components/free-consultation';
import Recognition from 'components/recognition';
import Tabs from 'components/tabs';
import Layout from '../components/layout';
import Blog from 'components/blog/widget';
import {
  Gokada,
  KoroBox,
  LadderOut,
  Anuel,
  MyCurrency,
  RevenueBoosters,
} from 'components/carousel';
import loadable from '@loadable/component';
import identificators from 'components/googleAnalyticsIdentificators';

import styles from '../technologies.module.css';

import { meta } from '../../../metaData';
import FAQ from 'components/faq';
import { useFaqData } from 'hooks/useFaqData';

const LIST = [
  {
    title: 'Faster time-to-market',
    text: 'React Native elements, and cross-platform features make it possible for us to <span>create one app for both iOS and Android at the same time</span>. Hence, you save your business budget, and the time spent on the development stage is reduced twice, resulting in a faster time-to-market.',
  },
  {
    title: 'Time-proof stability',
    text: 'As an open-source framework, React Native is <span>backed by a robust and active community, including Facebook engineers</span> and external specialists. The technology is constantly being improved as well as there are a lot of seasoned experts that are ready to share their knowledge and help you out with a project.',
  },
  {
    title: 'Open-ended features',
    text: "The React's environment enjoys one of the <span>best sets of ready-to-use debugging and design components</span> that developers can utilize for almost any project and idea. That said, we smoothly empower your solution with new functionality to maximize your ROI and ensure you come up with a high-end software product.",
  },
];

const TABS_TITLES = [
  {
    title: 'Ride-sharing applications',
  },
  {
    title: 'Social network platforms',
  },
  {
    title: 'SaaS-driven products',
  },
  {
    title: 'Food delivery solutions',
  },
  {
    title: 'E-commerce applications',
  },
];

interface Props {
  pathContext: {
    prePost: ReadonlyArray<any>;
  };
  location: Location;
}

const SwiperSlider = loadable(() => import('components/swiper-slider'));

function ReactNative({ pathContext: { prePost }, location }: Props) {
  const faqData = useFaqData('allStrapiTechnology', 'React Native');

  return (
    <Layout
      title="React Native Development Services"
      subtitle="We build top-quality web and mobile solutions for both iOS and Android platforms at the same time, thus drastically reducing your expenses and enabling you to hit the market in less than no time."
      linkText="Estimate your project"
      metaData={{ main: meta.reactNative, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.ESTIMATE_YOUR_PROJECT_HEADER}
    >
      <section className={clsx('section', 'gray-wave')}>
        <div className="inner">
          <h2 className="title">What’s so special about React Native?</h2>
          <p className="subtitle">Achieve your business goals with cutting-edge advantages</p>
          <Bullets className={styles.bullets} list={LIST} />
        </div>
      </section>
      <section className="section">
        <div className="inner">
          <h2 className="title">Our React Native expertise</h2>
          <p className="subtitle">
            Driven by a solid grasp of the React Native environment, we maintain the highest quality
            of code to cope with up-to-date requirements.
          </p>
          <Tabs list={TABS_TITLES} isArrow btnClassName="tabs__btn_30">
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  React Native allows for linking third-party plugins with a native module to
                  simplify the integration of vital geolocation functionalities into a ride-sharing
                  app, whether that be trajectory monitoring, GPS tracking, geofencing and so on.
                  Moreover, once you opt for a React Native framework, you don’t have to hire a
                  native language expert to upgrade the platform you are working with at the moment,
                  which by itself saves your precious time and resources.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  It’s crucial to keep your application updated in accordance with robust security
                  protocols and other significant maintenance changes. Thanks to a native approach,
                  you can promptly modify your social network with new features regardless of what
                  framework and programming language it was initially based on. Since you perform
                  the whole process for both operating systems at once, the code is more stable so
                  you can enjoy enhanced app productivity and faster time-to-market.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  To ensure high flexibility and customization capability of a SaaS application, it
                  would be a wise choice to stick to a React Native framework thanks to the support
                  of third-party modules and plugins. We use these add-ons to empower your app with
                  additional stunning UI elements so you can customize your templates and come up
                  with improved user experience.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  React Native is a great option when it comes to modifying on-demand food ordering
                  apps with fast and easy-to-maintain updates. Besides, this framework allows us to
                  smoothly change the existing code and provide new user-friendly UI elements to
                  boost your user experience and satisfaction. React Native also offers decent
                  support for third-party APIs for on-demand apps that require additional payment
                  systems and maps.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  React Native enhances the release time while ensuring an application excellent
                  performance based on user-oriented UIs both for iOS and Android platforms. Thanks
                  to a modular architecture, React Native is one of the best fits for creating
                  e-commerce solutions since it can break the whole app into small elements, thus
                  ensuring remarkable flexibility and upgradability of each piece.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
          </Tabs>
        </div>
      </section>
      <section className={styles.carousel}>
        <SwiperSlider>
          <RevenueBoosters />
          <Gokada />
          <KoroBox />
          <LadderOut />
          <Anuel />
          <MyCurrency />
        </SwiperSlider>
      </section>
      <section className="section">
        <div className="inner">
          <h2 className="title">Recognition</h2>
          <Recognition />
        </div>
      </section>
      <GetConsultation
        text="Transforming disruptive ideas into top-notch solutions"
        title="Hire our React Native developers to build your app"
        linkId={identificators.ESTIMATE_A_PROJECT_BANNER}
      />
      <section className="section">
        <div className="inner">
          <FAQ data={faqData} />
          <Blog posts={prePost} />
        </div>
      </section>
      <Booking />
    </Layout>
  );
}

export default ReactNative;
